import React, { useState } from "react"
import styles from "./footer.module.scss"
import { Row, Col, Modal, ModalBody } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import UserAgreement from "./../../../static/docs/UserAgreement.pdf"
import PrivacyandCookiesPolicy from "./../../../static/docs/PrivacyandCookiesPolicy.pdf"
import Formulario from "../elements/form"
import StylesModal from "../../styles/modal.scss"

const GridFooter = props => {
  const [modal4, setModal4] = useState(false)
  const toggleModal4 = () => setModal4(!modal4)

  const [modal3, setModal3] = useState(false)
  const toggleModal3 = () => setModal3(!modal3)

  const [modal2, setModal2] = useState(false)
  const toggleModal2 = () => setModal2(!modal2)

  const [modal, setModal] = useState(false)
  const toggleModal = () => setModal(!modal)
  return (
    <Row className="m-0">
      <section className={styles.sectionDark}>
        <Row className="p-0 mt-5 mx-5">
          <Col
            xs="12"
            lg="4"
            className="justify-content-center justify-content-around align-items-center"
          >
            <div
              className={
                styles.iconcontainer +
                "container my-auto d-flex justify-content-start mb-3 mx-lg-0 px-5 px-lg-0"
              }
            >
              <img
                src="/images/logos/logo-smart-monday1.png"
                alt="Card"
                className={styles.ibanLogo + " "}
              />
            </div>
            <div className="d-flex flex-column mt-5">
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a onClick={toggleModal}>For investors</a>
              </span>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a onClick={toggleModal2}>For lending companies</a>
              </span>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a onClick={toggleModal3}>Affiliate program</a>
              </span>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a onClick={toggleModal4}>Register your company</a>
              </span>
            </div>
          </Col>
          <Col
            xs="12"
            lg="4"
            className="justify-content-center justify-content-around align-items-center h-100 mt-5"
          >
            <div className="d-flex justify-content-center flex-column my-5 mx-lg-5 px-5">
              <h4 className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                About us
              </h4>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a href="/howItWorks">How it works</a>
              </span>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a href="/security">Security</a>
              </span>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a href="/risk">Risk</a>
              </span>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a href="/pspInfo">PSP info</a>
              </span>
            </div>
          </Col>
          <Col
            xs="12"
            lg="4"
            className="justify-content-center justify-content-around align-items-center mt-5"
          >
            <div className="d-flex justify-content-center flex-column my-5">
              <h4 className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                Support
              </h4>
              <span className={styles.powered + " pb-0 pt-0 w-100  mx-auto"}>
                <a href="mailto:info@smart-monday.com">info@smart-monday.com</a>
              </span>
            </div>
          </Col>
          <Col xs="12" className="">
            <hr />
            <small>
              <span className={styles.powered}>
                <a href={UserAgreement} target="_blank">
                  User Agreement
                </a>{" "}
                <a
                  href={PrivacyandCookiesPolicy}
                  target="_blank"
                  className="mx-4"
                >
                  Privacy policy
                </a>{" "}
                <br />
                © 2020 All rights reserved. By using our website you agree to
                the use of cookies in accordance with our Privacy and Cookies
                policy.
                <br />
                <br />
              </span>
            </small>
          </Col>
        </Row>
      </section>
      <Modal isOpen={modal} cssModule={StylesModal} toggle={toggleModal}>
        <div className="text-right py-3 px-4">
          <img
            src="/images/icons/x-blue.svg"
            className={styles.hoverCursor}
            alt="close icon"
            onClick={toggleModal}
          />
        </div>
        <div
          className={
            styles.iconcontainer + " my-auto d-flex justify-content-center"
          }
        >
          <img
            src="/images/logos/logo-smart-monday1.png"
            alt="Card"
            className={styles.ibanLogoModal + " pr-0 pl-md-5"}
          />
        </div>
        <ModalBody className="px-3 my-3">
          <div className="d-none d-lg-block mx-auto">
            <Formulario field={props.field} type={"Apply"} />
          </div>
          <div className="d-lg-none mx-auto">
            <Formulario field={props.field} type={"Apply"} />
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal2} cssModule={StylesModal} toggle={toggleModal2}>
        <div className="text-right py-3 px-4">
          <img
            src="/images/icons/x-blue.svg"
            className={styles.hoverCursor}
            alt="close icon"
            onClick={toggleModal2}
          />
        </div>
        <div
          className={
            styles.iconcontainer + " my-auto d-flex justify-content-center"
          }
        >
          <img
            src="/images/logos/logo-smart-monday1.png"
            alt="Card"
            className={styles.ibanLogoModal + " pr-0 pl-md-5"}
          />
        </div>
        <ModalBody className="px-3 my-3">
          <div className="d-none d-lg-block mx-auto">
            <Formulario field={props.field} type={"Companies"} />
          </div>
          <div className="d-lg-none mx-auto">
            <Formulario field={props.field} type={"Companies"} />
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal3} cssModule={StylesModal} toggle={toggleModal3}>
        <div className="text-right py-3 px-4">
          <img
            src="/images/icons/x-blue.svg"
            className={styles.hoverCursor}
            alt="close icon"
            onClick={toggleModal3}
          />
        </div>
        <div
          className={
            styles.iconcontainer + " my-auto d-flex justify-content-center"
          }
        >
          <img
            src="/images/logos/logo-smart-monday1.png"
            alt="Card"
            className={styles.ibanLogoModal + " pr-0 pl-md-5"}
          />
        </div>
        <ModalBody className="px-3 my-3">
          <div className="d-none d-lg-block mx-auto">
            <Formulario field={props.field} type={"Affiliate"} />
          </div>
          <div className="d-lg-none mx-auto">
            <Formulario field={props.field} type={"Affiliate"} />
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal4} cssModule={StylesModal} toggle={toggleModal4}>
        <div className="text-right py-3 px-4">
          <img
            src="/images/icons/x-blue.svg"
            className={styles.hoverCursor}
            alt="close icon"
            onClick={toggleModal3}
          />
        </div>
        <div
          className={
            styles.iconcontainer + " my-auto d-flex justify-content-center"
          }
        >
          <img
            src="/images/logos/logo-smart-monday1.png"
            alt="Card"
            className={styles.ibanLogoModal + " pr-0 pl-md-5"}
          />
        </div>
        <ModalBody className="px-3 my-3">
          <div className="d-none d-lg-block mx-auto">
            <Formulario field={props.field} type={"Register"} />
          </div>
          <div className="d-lg-none mx-auto">
            <Formulario field={props.field} type={"Register"} />
          </div>
        </ModalBody>
      </Modal>
    </Row>
  )
}
export default props => (
  <footer className={styles.footerContainer}>
    <GridFooter />
  </footer>
)
